import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: ()=>import('../views/index.vue')
  },
  {
    path: '/index_en',
    name: 'Home_en',
    component: ()=>import('../views/index_en.vue')
  },
  {
    path: '/signature',
    name: 'Signatrue',
    component: ()=>import('../views/signature.vue')
  },
  {
    path: '/signature_en',
    name: 'Signatrue_en',
    component: ()=>import('../views/signature_en.vue')
  },
  {
    path: '/custSign',
    name: 'CustSign',
    component: ()=>import('../views/custSign/index.vue')
  },
  {
    path: '/esign',
    name: 'Esign',
    component: ()=>import('../views/esign.vue')
  },
  {
    path: '/test',
    name: 'Test',
    component: ()=>import('../views/test.vue')
  },
  // {
    // path: '/about',
    // name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
