<template>
  <div>
    <div style="transform: rotate(0deg)">
      <!-- vue2 -->
      <div v-if="!show_sucess">
        <vue-esign
          ref="esign"
          :width="esignSize.width"
          :height="esignSize.height"
          :isCrop="isCrop"
          :lineWidth="lineWidth"
          :lineColor="lineColor"
          :bgColor.sync="bgColor"
          :isClearBgColor="false"
        />
      </div>

      <!-- isClearBgColor为false时，不必再给bgColor加sync修饰符或v-model -->

      <div class="footer" v-if="!show_sucess">
        <div>
          <button @click="handleReset" type="button" class="btn btn-secondary" :disabled="sub_disabled" style="width: 250px; height: 50px">
            清除
          </button>
        </div>
        <div>
          <button
            @click="handleGenerate"
            type="button"
            :disabled="sub_disabled"
            class="btn btn-success"
            style="width: max-content; height: 50px"
          >
            提交（签署法人中文拼音名字）
          </button>
        </div>
      </div>
      <!-- 成功 -->
      <div v-else class="result">
        <img src="../stactic/image/sucess.png" alt="" />
        <div class="res">签署完成</div>
      </div>
      <!-- <button @click="handleReset">清空画板</button>
      <button @click="handleGenerate">生成图片</button> -->
    </div>

    <Confirm ref="Confirm"></Confirm>
  </div>
</template>

<script>
// 局部
import vueEsign from "vue-esign"
import base64ToFile from "../stactic/js/base64ToFile"
import Confirm from "../components/Confirm.vue"
export default {
  components: { vueEsign, Confirm },
  data() {
    return {
      lineWidth: 6,
      lineColor: "#000000",
      bgColor: "transparent",
      resultImg: "",
      isCrop: false,
      sub_disabled: false,
      signature: true,
      // 订单 编号
      order_no: "",
      // 定单的 文件lsit  json
      file_list: "",
      show_sucess: false,
    }
  },
  mounted() {
    this.order_no = this.$route.query.order_no
    this.file_list = this.$route.query.file_list
  },
  computed: {
    esignSize() {
      return {
        width: window.innerWidth,
        height: window.innerHeight,
      }
    },
  },
  methods: {
    handleReset() {
      this.$refs.esign.reset()
    },
    async handleGenerate() {
      try {
        this.sub_disabled = true
        const imgBase64 = await this.$refs.esign.generate({ format: "image/jpeg", quality: 0.5 })
        this.$refs.Confirm.confirm()
          .then(async () => {
            const file = base64ToFile(imgBase64)
            // 开始上传签名图片
            let formdata = new FormData()
            formdata.append("file", file)
            const res = await this.$axios.post("/commonUploadFiles", formdata)
            if (res.data.code == 200) {
              // 电子签名地址
              const signature_path = res.data.data[0]
              // 提交电子签名
              const r = await this.$axios.post("/signature/addSignature", {
                order_no: this.order_no,
                signature_path: signature_path,
                file_list: this.file_list,
              })
              if (r.data.code == 200) {
                this.show_sucess = true
              } else {
                alert(r.data.msg)
                this.sub_disabled = false
              }
            }
          })
          .catch((e) => {
            this.sub_disabled = false
          })
      } catch (e) {
        this.sub_disabled = false
        alert("请先完成签名")
      }
    },
  },
}
</script>

<style scoped>
.footer {
  width: 100px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: gray;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.footer > div {
  transform: rotate(90deg);
}
</style>
