function base64ToBlob(base64Data,filename='file') {
  let arr = base64Data.split(',');
  let mime = arr[0].match(/:(.*?);/)[1];
  let bstr = atob(arr[1]);
  let n = bstr.length;
  let u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  const blob =  new Blob([u8arr], { type: mime });
  const file = new File([blob], filename, {type: blob.type});
  return file
}

// function blobToFile(theBlob, filename) {
//     return new File([theBlob], filename, {type: theBlob.type});
// }

// // 示例
// let base64Image = 'data:image/png;base64,iVBORw0KGgo...'; // 替换为你的 Base64 数据
// let blob = base64ToBlob(base64Image);
// let file = blobToFile(blob, 'image.png');
// console.log(file);

export default base64ToBlob